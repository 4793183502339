
































import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class Details extends Vue {
  // 参数集合
  @Prop() private list!: PropModel[];
  // 弹窗标题
  @Prop({ default: "查看详情" }) private title!: string;
  // label宽度
  @Prop({ default: 120 }) private labelwidth!: number;
  // 是否显示弹窗
  @Prop() private dialog!: false;
  // 弹窗宽度
  @Prop({ default: 630 }) private width!: number;
  // 多列
  @Prop({ default: 2 }) private col!: number;
  // 按钮显示
  @Prop({ default: false }) private isBtn!: boolean;
  // 按钮文字
  @Prop({ default: "确 定" }) private btnVal!: string;

  // 计算宽度
  getValueWidth(item: any) {
    if (item.multiline) {
      return this.width - 40 - 12 - this.labelwidth;
    }
    return (this.width - 40 - 24 - this.labelwidth * 2) / this.col;
  }

  getMultiline(item: any) {
    if (!item.multiline) {
      return 0;
    }
    return this.width - 40;
  }

  // 关闭回调
  closeDialog() {
    this.$emit("close");
  }

  //确定按钮
  sure() {
    this.$emit('sure')
  }
}

// 参数对象
interface PropModel {
  // 要显示的标题
  name: string;
  // 要显示的内容
  value: string;
  // 是否一行显示
  multiline: boolean;
}
